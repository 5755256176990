var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
// Native JavaScript for Bootstrap v3.0.13 | 2020 © dnp_theme | MIT-License
!function (t, e) {
  exports = e();
}(exports, function () {
  "use strict";

  var t = "webkitTransition" in document.head.style ? "webkitTransitionEnd" : "transitionend",
      e = "webkitTransition" in document.head.style || "transition" in document.head.style,
      i = "webkitTransition" in document.head.style ? "webkitTransitionDuration" : "transitionDuration",
      n = "webkitTransition" in document.head.style ? "webkitTransitionProperty" : "transitionProperty";

  function s(t) {
    var s = getComputedStyle(t),
        o = s[n],
        a = e && o && "none" !== o ? parseFloat(s[i]) : 0;
    return isNaN(a) ? 0 : 1000 * a;
  }

  function o(e, i) {
    var n = 0;
    s(e) ? e.addEventListener(t, function s(o) {
      !n && i(o), n = 1, e.removeEventListener(t, s);
    }) : setTimeout(function () {
      !n && i(), n = 1;
    }, 17);
  }

  function a(t, e) {
    var i = e && e instanceof Element ? e : document;
    return t instanceof Element ? t : i.querySelector(t);
  }

  function l(t, e, i) {
    var n = new CustomEvent(t + ".bs." + e, {
      cancelable: !0
    });
    return n.relatedTarget = i, n;
  }

  function c(t) {
    (this || _global) && this.dispatchEvent(t);
  }

  function r(t) {
    var e,
        i = this || _global,
        n = l("close", "alert"),
        s = l("closed", "alert");

    function r(e) {
      t[e = e ? "addEventListener" : "removeEventListener"]("click", d, !1);
    }

    function d(n) {
      e = n && n.target.closest(".alert"), (t = a("[data-dismiss=\"alert\"]", e)) && e && (t === n.target || t.contains(n.target)) && i.close();
    }

    function u() {
      r(), e.parentNode.removeChild(e), c.call(e, s);
    }

    i.close = function () {
      if (e && t && e.classList.contains("show")) {
        if (c.call(e, n), n.defaultPrevented) return;
        i.dispose(), e.classList.remove("show"), e.classList.contains("fade") ? o(e, u) : u();
      }
    }, i.dispose = function () {
      r(), delete t.Alert;
    }, t = a(t), e = t.closest(".alert"), t.Alert && t.Alert.dispose(), t.Alert || r(1), i.element = t, t.Alert = i;
  }

  function d(t) {
    var e,
        i = l("change", "button");

    function n(n) {
      var s,
          o = "LABEL" === n.target.tagName ? n.target : n.target.closest("LABEL") ? n.target.closest("LABEL") : null;

      if (s = o && o.getElementsByTagName("INPUT")[0]) {
        if (c.call(s, i), c.call(t, i), "checkbox" === s.type) {
          if (i.defaultPrevented) return;
          s.checked ? (o.classList.remove("active"), s.getAttribute("checked"), s.removeAttribute("checked"), s.checked = !1) : (o.classList.add("active"), s.getAttribute("checked"), s.setAttribute("checked", "checked"), s.checked = !0), t.toggled || (t.toggled = !0);
        }

        if ("radio" === s.type && !t.toggled) {
          if (i.defaultPrevented) return;
          (!s.checked || 0 === n.screenX && 0 == n.screenY) && (o.classList.add("active"), o.classList.add("focus"), s.setAttribute("checked", "checked"), s.checked = !0, t.toggled = !0, Array.from(e).map(function (t) {
            var e = t.getElementsByTagName("INPUT")[0];
            t !== o && t.classList.contains("active") && (c.call(e, i), t.classList.remove("active"), e.removeAttribute("checked"), e.checked = !1);
          }));
        }

        setTimeout(function () {
          t.toggled = !1;
        }, 50);
      }
    }

    function s(t) {
      32 === (t.which || t.keyCode) && t.target === document.activeElement && n(t);
    }

    function o(t) {
      32 === (t.which || t.keyCode) && t.preventDefault();
    }

    function r(t) {
      if ("INPUT" === t.target.tagName) {
        var e = "focusin" === t.type ? "add" : "remove";
        t.target.closest(".btn").classList[e]("focus");
      }
    }

    function d(e) {
      t[e = e ? "addEventListener" : "removeEventListener"]("click", n, !1), t[e]("keyup", s, !1), t[e]("keydown", o, !1), t[e]("focusin", r, !1), t[e]("focusout", r, !1);
    }

    (this || _global).dispose = function () {
      d(), delete t.Button;
    }, (t = a(t)).Button && t.Button.dispose(), (e = t.getElementsByClassName("btn")).length && (t.Button || d(1), t.toggled = !1, t.Button = this || _global, Array.from(e).map(function (t) {
      !t.classList.contains("active") && a("input:checked", t) && t.classList.add("active"), t.classList.contains("active") && !a("input:checked", t) && t.classList.remove("active");
    }));
  }

  var u = "onmouseleave" in document ? ["mouseenter", "mouseleave"] : ["mouseover", "mouseout"],
      m = !!function () {
    var t = !1;

    try {
      var e = Object.defineProperty({}, "passive", {
        get: function () {
          t = !0;
        }
      });
      document.addEventListener("DOMContentLoaded", function t() {
        document.removeEventListener("DOMContentLoaded", t, e);
      }, e);
    } catch (t) {}

    return t;
  }() && {
    passive: !0
  };

  function f(t, e) {
    e = e || {};
    var i,
        n,
        r,
        d,
        f,
        h,
        g,
        p,
        v,
        L = this || _global;

    function b() {
      !1 === n.interval || t.classList.contains("paused") || (t.classList.add("paused"), !i.isSliding && (clearInterval(i.timer), i.timer = null));
    }

    function y() {
      !1 !== n.interval && t.classList.contains("paused") && (t.classList.remove("paused"), !i.isSliding && (clearInterval(i.timer), i.timer = null), !i.isSliding && L.cycle());
    }

    function w(t) {
      if (t.preventDefault(), !i.isSliding) {
        var e = t.target;
        if (!e || e.classList.contains("active") || !e.getAttribute("data-slide-to")) return !1;
        i.index = parseInt(e.getAttribute("data-slide-to")), L.slideTo(i.index);
      }
    }

    function A(t) {
      if (t.preventDefault(), !i.isSliding) {
        var e = t.currentTarget || t.srcElement;
        e === g ? i.index++ : e === h && i.index--, L.slideTo(i.index);
      }
    }

    function T(t) {
      var e = t.which;

      if (!i.isSliding) {
        switch (e) {
          case 39:
            i.index++;
            break;

          case 37:
            i.index--;
            break;

          default:
            return;
        }

        L.slideTo(i.index);
      }
    }

    function E(e) {
      e = e ? "addEventListener" : "removeEventListener", n.pause && n.interval && (t[e](u[0], b, !1), t[e](u[1], y, !1), t[e]("touchstart", b, m), t[e]("touchend", y, m)), n.touch && f.length > 1 && t[e]("touchstart", C, m), g && g[e]("click", A, !1), h && h[e]("click", A, !1), p && p[e]("click", w, !1), n.keyboard && window[e]("keydown", T, !1);
    }

    function k(e) {
      t[e = e ? "addEventListener" : "removeEventListener"]("touchmove", x, m), t[e]("touchend", N, m);
    }

    function C(e) {
      i.isTouch || (i.touchPosition.startX = e.changedTouches[0].pageX, t.contains(e.target) && (i.isTouch = !0, k(1)));
    }

    function x(t) {
      if (i.isTouch) return i.touchPosition.currentX = t.changedTouches[0].pageX, "touchmove" === t.type && t.changedTouches.length > 1 ? (t.preventDefault(), !1) : void 0;
      t.preventDefault();
    }

    function N(e) {
      if (i.isTouch && !i.isSliding && (i.touchPosition.endX = i.touchPosition.currentX || e.changedTouches[0].pageX, i.isTouch)) {
        if ((!t.contains(e.target) || !t.contains(e.relatedTarget)) && Math.abs(i.touchPosition.startX - i.touchPosition.endX) < 75) return !1;
        i.touchPosition.currentX < i.touchPosition.startX ? i.index++ : i.touchPosition.currentX > i.touchPosition.startX && i.index--, i.isTouch = !1, L.slideTo(i.index), k();
      }
    }

    function P(t) {
      Array.from(v).map(function (t) {
        t.classList.remove("active");
      }), v[t] && v[t].classList.add("active");
    }

    function B(e) {
      if (i.touchPosition) {
        var s = i.index,
            o = e && e.target !== f[s] ? 1000 * e.elapsedTime + 100 : 20,
            a = L.getActiveIndex(),
            l = "left" === i.direction ? "next" : "prev";
        i.isSliding && setTimeout(function () {
          i.touchPosition && (i.isSliding = !1, f[s].classList.add("active"), f[a].classList.remove("active"), f[s].classList.remove("carousel-item-" + l), f[s].classList.remove("carousel-item-" + i.direction), f[a].classList.remove("carousel-item-" + i.direction), c.call(t, d), document.hidden || !n.interval || t.classList.contains("paused") || L.cycle());
        }, o);
      }
    }

    if (L.cycle = function () {
      i.timer && (clearInterval(i.timer), i.timer = null), i.timer = setInterval(function () {
        var e = i.index || L.getActiveIndex();
        (function (t) {
          var e = t.getBoundingClientRect(),
              i = window.innerHeight || document.documentElement.clientHeight;
          return e.top <= i && e.bottom >= 0;
        })(t) && (e++, L.slideTo(e));
      }, n.interval);
    }, L.slideTo = function (e) {
      if (!i.isSliding) {
        var a,
            u = L.getActiveIndex();
        u !== e && (u < e || 0 === u && e === f.length - 1 ? i.direction = "left" : (u > e || u === f.length - 1 && 0 === e) && (i.direction = "right"), e < 0 ? e = f.length - 1 : e >= f.length && (e = 0), a = "left" === i.direction ? "next" : "prev", r = l("slide", "carousel", f[e]), d = l("slid", "carousel", f[e]), c.call(t, r), r.defaultPrevented || (i.index = e, i.isSliding = !0, clearInterval(i.timer), i.timer = null, P(e), s(f[e]) && t.classList.contains("slide") ? (f[e].classList.add("carousel-item-" + a), f[e].offsetWidth, f[e].classList.add("carousel-item-" + i.direction), f[u].classList.add("carousel-item-" + i.direction), o(f[e], B)) : (f[e].classList.add("active"), f[e].offsetWidth, f[u].classList.remove("active"), setTimeout(function () {
          i.isSliding = !1, n.interval && t && !t.classList.contains("paused") && L.cycle(), c.call(t, d);
        }, 100))));
      }
    }, L.getActiveIndex = function () {
      return Array.from(f).indexOf(t.getElementsByClassName("carousel-item active")[0]) || 0;
    }, L.dispose = function () {
      var e = ["left", "right", "prev", "next"];
      Array.from(f).map(function (t, i) {
        t.classList.contains("active") && P(i), e.map(function (e) {
          return t.classList.remove("carousel-item-" + e);
        });
      }), clearInterval(i.timer), E(), i = {}, n = {}, delete t.Carousel;
    }, (t = a(t)).Carousel && t.Carousel.dispose(), f = t.getElementsByClassName("carousel-item"), h = t.getElementsByClassName("carousel-control-prev")[0], g = t.getElementsByClassName("carousel-control-next")[0], p = t.getElementsByClassName("carousel-indicators")[0], v = p && p.getElementsByTagName("LI") || [], !(f.length < 2)) {
      var S = t.getAttribute("data-interval"),
          H = "false" === S ? 0 : parseInt(S),
          M = "false" === t.getAttribute("data-touch") ? 0 : 1,
          I = "hover" === t.getAttribute("data-pause") || !1,
          D = "true" === t.getAttribute("data-keyboard") || !1,
          X = e.interval,
          W = e.touch;
      (n = {}).keyboard = !0 === e.keyboard || D, n.pause = !("hover" !== e.pause && !I) && "hover", n.touch = W || M, n.interval = "number" == typeof X ? X : !1 === X || 0 === H || !1 === H ? 0 : isNaN(H) ? 5000 : H, L.getActiveIndex() < 0 && (f.length && f[0].classList.add("active"), v.length && P(0)), (i = {}).direction = "left", i.index = 0, i.timer = null, i.isSliding = !1, i.isTouch = !1, i.touchPosition = {
        startX: 0,
        currentX: 0,
        endX: 0
      }, E(1), n.interval && L.cycle(), t.Carousel = L;
    }
  }

  function h(t, e) {
    e = e || {};
    var i,
        n,
        s,
        r,
        d,
        u,
        m = this || _global,
        f = null,
        h = null;

    function g(t, e) {
      c.call(t, d), d.defaultPrevented || (t.isAnimating = !0, t.style.height = t.scrollHeight + "px", t.classList.remove("collapse"), t.classList.remove("show"), t.classList.add("collapsing"), t.offsetWidth, t.style.height = "0px", o(t, function () {
        t.isAnimating = !1, t.setAttribute("aria-expanded", "false"), e.setAttribute("aria-expanded", "false"), t.classList.remove("collapsing"), t.classList.add("collapse"), t.style.height = "", c.call(t, u);
      }));
    }

    m.toggle = function (e) {
      (e && "A" === e.target.tagName || "A" === t.tagName) && e.preventDefault(), (t.contains(e.target) || e.target === t) && (h.classList.contains("show") ? m.hide() : m.show());
    }, m.hide = function () {
      h.isAnimating || (g(h, t), t.classList.add("collapsed"));
    }, m.show = function () {
      var e, l;
      f && (i = f.getElementsByClassName("collapse show")[0], n = i && (a("[data-target=\"#" + i.id + "\"]", f) || a("[href=\"#" + i.id + "\"]", f))), h.isAnimating || (n && i !== h && (g(i, n), n.classList.add("collapsed")), e = h, l = t, c.call(e, s), s.defaultPrevented || (e.isAnimating = !0, e.classList.add("collapsing"), e.classList.remove("collapse"), e.style.height = e.scrollHeight + "px", o(e, function () {
        e.isAnimating = !1, e.setAttribute("aria-expanded", "true"), l.setAttribute("aria-expanded", "true"), e.classList.remove("collapsing"), e.classList.add("collapse"), e.classList.add("show"), e.style.height = "", c.call(e, r);
      })), t.classList.remove("collapsed"));
    }, m.dispose = function () {
      t.removeEventListener("click", m.toggle, !1), delete t.Collapse;
    }, (t = a(t)).Collapse && t.Collapse.dispose();
    var p = t.getAttribute("data-parent");
    s = l("show", "collapse"), r = l("shown", "collapse"), d = l("hide", "collapse"), u = l("hidden", "collapse"), (h = a(e.target || t.getAttribute("data-target") || t.getAttribute("href"))).isAnimating = !1, f = t.closest(e.parent || p), t.Collapse || t.addEventListener("click", m.toggle, !1), t.Collapse = m;
  }

  function g(t) {
    t.focus ? t.focus() : t.setActive();
  }

  function p(t, e) {
    var i,
        n,
        s,
        o,
        r,
        d,
        u,
        m = this || _global,
        f = null,
        h = [];

    function p(t) {
      (t.href && "#" === t.href.slice(-1) || t.parentNode && t.parentNode.href && "#" === t.parentNode.href.slice(-1)) && this.preventDefault();
    }

    function v() {
      var e = t.open ? "addEventListener" : "removeEventListener";
      document[e]("click", L, !1), document[e]("keydown", y, !1), document[e]("keyup", w, !1), document[e]("focus", L, !1);
    }

    function L(e) {
      var i = e.target,
          n = i && (i.getAttribute("data-toggle") || i.parentNode && i.parentNode.getAttribute && i.parentNode.getAttribute("data-toggle"));
      ("focus" !== e.type || i !== t && i !== d && !d.contains(i)) && (i !== d && !d.contains(i) || !u && !n) && (f = i === t || t.contains(i) ? t : null, m.hide(), p.call(e, i));
    }

    function b(e) {
      f = t, m.show(), p.call(e, e.target);
    }

    function y(t) {
      var e = t.which || t.keyCode;
      38 !== e && 40 !== e || t.preventDefault();
    }

    function w(e) {
      var i = e.which || e.keyCode,
          n = document.activeElement,
          s = n === t,
          o = d.contains(n),
          a = n.parentNode === d || n.parentNode.parentNode === d,
          l = h.indexOf(n);
      a && (l = s ? 0 : 38 === i ? l > 1 ? l - 1 : 0 : 40 === i && l < h.length - 1 ? l + 1 : l, h[l] && g(h[l])), (h.length && a || !h.length && (o || s) || !o) && t.open && 27 === i && (m.toggle(), f = null);
    }

    m.show = function () {
      i = l("show", "dropdown", f), c.call(r, i), i.defaultPrevented || (d.classList.add("show"), r.classList.add("show"), t.setAttribute("aria-expanded", !0), t.open = !0, t.removeEventListener("click", b, !1), setTimeout(function () {
        g(d.getElementsByTagName("INPUT")[0] || t), v(), n = l("shown", "dropdown", f), c.call(r, n);
      }, 1));
    }, m.hide = function () {
      s = l("hide", "dropdown", f), c.call(r, s), s.defaultPrevented || (d.classList.remove("show"), r.classList.remove("show"), t.setAttribute("aria-expanded", !1), t.open = !1, v(), g(t), setTimeout(function () {
        t.Dropdown && t.addEventListener("click", b, !1);
      }, 1), o = l("hidden", "dropdown", f), c.call(r, o));
    }, m.toggle = function () {
      r.classList.contains("show") && t.open ? m.hide() : m.show();
    }, m.dispose = function () {
      r.classList.contains("show") && t.open && m.hide(), t.removeEventListener("click", b, !1), delete t.Dropdown;
    }, (t = a(t)).Dropdown && t.Dropdown.dispose(), r = t.parentNode, d = a(".dropdown-menu", r), Array.from(d.children).map(function (t) {
      t.children.length && "A" === t.children[0].tagName && h.push(t.children[0]), "A" === t.tagName && h.push(t);
    }), t.Dropdown || (!("tabindex" in d) && d.setAttribute("tabindex", "0"), t.addEventListener("click", b, !1)), u = !0 === e || "true" === t.getAttribute("data-persist") || !1, t.open = !1, t.Dropdown = m;
  }

  function v(t, e) {
    e = e || {};
    var i,
        n,
        r,
        d,
        u,
        f,
        h,
        p,
        v,
        L = this || _global,
        b = null,
        y = {};

    function w() {
      var t,
          e,
          n = document.body.classList.contains("modal-open"),
          s = parseInt(getComputedStyle(document.body).paddingRight),
          o = document.documentElement.clientHeight !== document.documentElement.scrollHeight || document.body.clientHeight !== document.body.scrollHeight,
          a = i.clientHeight !== i.scrollHeight;
      (e = document.createElement("div")).className = "modal-scrollbar-measure", document.body.appendChild(e), t = e.offsetWidth - e.clientWidth, document.body.removeChild(e), f = t, i.style.paddingRight = !a && f ? f + "px" : "", document.body.style.paddingRight = a || o ? s + (n ? 0 : f) + "px" : "", v.length && v.map(function (t) {
        var e = getComputedStyle(t).paddingRight;
        t.style.paddingRight = a || o ? parseInt(e) + (n ? 0 : f) + "px" : parseInt(e) + "px";
      });
    }

    function A() {
      (h = a(".modal-backdrop")) && !document.getElementsByClassName("modal show")[0] && (document.body.removeChild(h), h = null), null === h && (document.body.classList.remove("modal-open"), document.body.style.paddingRight = "", i.style.paddingRight = "", v.length && v.map(function (t) {
        t.style.paddingRight = "";
      }));
    }

    function T(t) {
      t = t ? "addEventListener" : "removeEventListener", window[t]("resize", L.update, m), i[t]("click", P, !1), document[t]("keydown", N, !1);
    }

    function E() {
      i.style.display = "block", w(), !document.getElementsByClassName("modal show")[0] && document.body.classList.add("modal-open"), i.classList.add("show"), i.setAttribute("aria-hidden", !1), i.classList.contains("fade") ? o(i, k) : k();
    }

    function k() {
      g(i), i.isAnimating = !1, T(1), r = l("shown", "modal", b), c.call(i, r);
    }

    function C(e) {
      i.style.display = "", t && g(t), h = a(".modal-backdrop"), 1 !== e && h && h.classList.contains("show") && !document.getElementsByClassName("modal show")[0] ? (h.classList.remove("show"), o(h, A)) : A(), T(), i.isAnimating = !1, u = l("hidden", "modal"), c.call(i, u);
    }

    function x(e) {
      if (!i.isAnimating) {
        var n = e.target,
            s = "#" + i.getAttribute("id"),
            o = n.getAttribute("data-target") || n.getAttribute("href"),
            a = t.getAttribute("data-target") || t.getAttribute("href");
        !i.classList.contains("show") && (n === t && o === s || t.contains(n) && a === s) && (i.modalTrigger = t, b = t, L.show(), e.preventDefault());
      }
    }

    function N(t) {
      var e = t.which;
      !i.isAnimating && y.keyboard && 27 == e && i.classList.contains("show") && L.hide();
    }

    function P(t) {
      if (!i.isAnimating) {
        var e = t.target,
            n = "modal" === e.getAttribute("data-dismiss"),
            s = e.closest("[data-dismiss=\"modal\"]");
        i.classList.contains("show") && (s || n || e === i && "static" !== y.backdrop) && (L.hide(), b = null, t.preventDefault());
      }
    }

    L.toggle = function () {
      i.classList.contains("show") ? L.hide() : L.show();
    }, L.show = function () {
      if (!(i.classList.contains("show") && i.isAnimating || (n = l("show", "modal", b), c.call(i, n), n.defaultPrevented))) {
        i.isAnimating = !0;
        var t,
            e = document.getElementsByClassName("modal show")[0];
        e && e !== i && (e.modalTrigger && e.modalTrigger.Modal.hide(), e.Modal && e.Modal.hide()), y.backdrop && (t = document.createElement("div"), null === (h = a(".modal-backdrop")) && (t.setAttribute("class", "modal-backdrop" + (y.animation ? " fade" : "")), h = t, document.body.appendChild(h)), h = h), !h || e || h.classList.contains("show") || (h.offsetWidth, p = s(h), h.classList.add("show")), e ? E() : setTimeout(E, h && p ? p : 0);
      }
    }, L.hide = function (t) {
      i.classList.contains("show") && (d = l("hide", "modal"), c.call(i, d), d.defaultPrevented || (i.isAnimating = !0, i.classList.remove("show"), i.setAttribute("aria-hidden", !0), i.classList.contains("fade") && 1 !== t ? o(i, C) : C()));
    }, L.setContent = function (t) {
      a(".modal-content", i).innerHTML = t;
    }, L.update = function () {
      i.classList.contains("show") && w();
    }, L.dispose = function () {
      L.hide(1), t ? (t.removeEventListener("click", x, !1), delete t.Modal) : delete i.Modal;
    }, t = a(t);
    var B = a(t.getAttribute("data-target") || t.getAttribute("href"));
    i = t.classList.contains("modal") ? t : B, v = Array.from(document.getElementsByClassName("fixed-top")).concat(Array.from(document.getElementsByClassName("fixed-bottom"))), t.classList.contains("modal") && (t = null), t && t.Modal && t.Modal.dispose(), i && i.Modal && i.Modal.dispose(), y.keyboard = !1 !== e.keyboard && "false" !== i.getAttribute("data-keyboard"), y.backdrop = "static" !== e.backdrop && "static" !== i.getAttribute("data-backdrop") || "static", y.backdrop = !1 !== e.backdrop && "false" !== i.getAttribute("data-backdrop") && y.backdrop, y.animation = !!i.classList.contains("fade"), y.content = e.content, i.isAnimating = !1, t && !t.Modal && t.addEventListener("click", x, !1), y.content && L.setContent(y.content.trim()), t ? (i.modalTrigger = t, t.Modal = L) : i.Modal = L;
  }

  var L = "mousedown";

  function b() {
    return {
      y: window.pageYOffset || document.documentElement.scrollTop,
      x: window.pageXOffset || document.documentElement.scrollLeft
    };
  }

  function y(t, e, i, n) {
    var s,
        o,
        a,
        l,
        c,
        r,
        d = e.offsetWidth,
        u = e.offsetHeight,
        m = document.documentElement.clientWidth || document.body.clientWidth,
        f = document.documentElement.clientHeight || document.body.clientHeight,
        h = t.getBoundingClientRect(),
        g = n === document.body ? b() : {
      x: n.offsetLeft + n.scrollLeft,
      y: n.offsetTop + n.scrollTop
    },
        p = h.right - h.left,
        v = h.bottom - h.top,
        L = e.classList.contains("popover"),
        y = e.getElementsByClassName("arrow")[0],
        w = h.top + v / 2 - u / 2 < 0,
        A = h.left + p / 2 - d / 2 < 0,
        T = h.left + d / 2 + p / 2 >= m,
        E = h.top + u / 2 + v / 2 >= f,
        k = h.top - u < 0,
        C = h.left - d < 0,
        x = h.top + u + v >= f,
        N = h.left + d + p >= m;
    i = "right" === (i = "left" === (i = "bottom" === (i = "top" === (i = ("left" === i || "right" === i) && C && N ? "top" : i) && k ? "bottom" : i) && x ? "top" : i) && C ? "right" : i) && N ? "left" : i, -1 === e.className.indexOf(i) && (e.className = e.className.replace(/\b(top|bottom|left|right)+/, i)), c = y.offsetWidth, r = y.offsetHeight, "left" === i || "right" === i ? (o = "left" === i ? h.left + g.x - d - (L ? c : 0) : h.left + g.x + p, w ? (s = h.top + g.y, a = v / 2 - c) : E ? (s = h.top + g.y - u + v, a = u - v / 2 - c) : (s = h.top + g.y - u / 2 + v / 2, a = u / 2 - (L ? 0.9 * r : r / 2))) : "top" !== i && "bottom" !== i || (s = "top" === i ? h.top + g.y - u - (L ? r : 0) : h.top + g.y + v, A ? (o = 0, l = h.left + p / 2 - c) : T ? (o = m - 1.01 * d, l = d - (m - h.left) + p / 2 - c / 2) : (o = h.left + g.x - d / 2 + p / 2, l = d / 2 - (L ? c : c / 2))), e.style.top = s + "px", e.style.left = o + "px", a && (y.style.top = a + "px"), l && (y.style.left = l + "px");
  }

  function w(t, e) {
    e = e || {};
    var i,
        n,
        s,
        r,
        d,
        f,
        h,
        g,
        p,
        v,
        b,
        w,
        A,
        T,
        E,
        k,
        C,
        x,
        N,
        P = this || _global,
        B = null,
        S = 0,
        H = /(iPhone|iPod|iPad)/.test(navigator.userAgent),
        M = {};

    function I(t) {
      null !== B && t.target === a(".close", B) && P.hide();
    }

    function D() {
      return {
        0: e.title || t.getAttribute("data-title") || null,
        1: e.content || t.getAttribute("data-content") || null
      };
    }

    function X() {
      null === B && t.focus();
    }

    function W(e) {
      e = e ? "addEventListener" : "removeEventListener", "hover" === M.trigger ? (t[e](L, P.show), t[e](u[0], P.show), M.dismissible || t[e](u[1], P.hide)) : "click" == M.trigger ? t[e](M.trigger, P.toggle) : "focus" == M.trigger && (H && t[e]("click", X, !1), t[e](M.trigger, P.toggle));
    }

    function O(e) {
      B && B.contains(e.target) || e.target === t || t.contains(e.target) || P.hide();
    }

    function R(e) {
      e = e ? "addEventListener" : "removeEventListener", M.dismissible ? document[e]("click", I, !1) : ("focus" == M.trigger && t[e]("blur", P.hide), "hover" == M.trigger && document[e]("touchstart", O, m)), window[e]("resize", P.hide, m);
    }

    function z() {
      R(1), c.call(t, b);
    }

    function U() {
      R(), M.container.removeChild(B), S = null, B = null, c.call(t, A);
    }

    P.toggle = function () {
      null === B ? P.show() : P.hide();
    }, P.show = function () {
      clearTimeout(S), S = setTimeout(function () {
        if (null === B) {
          if (c.call(t, v), v.defaultPrevented) return;
          !function () {
            i = D()[0] || null, n = (n = D()[1]) ? n.trim() : null, B = document.createElement("div");
            var t = document.createElement("div");

            if (t.classList.add("arrow"), B.appendChild(t), null !== n && null === M.template) {
              if (B.setAttribute("role", "tooltip"), null !== i) {
                var e = document.createElement("h3");
                e.classList.add("popover-header"), e.innerHTML = M.dismissible ? i + p : i, B.appendChild(e);
              }

              var s = document.createElement("div");
              s.classList.add("popover-body"), s.innerHTML = M.dismissible && null === i ? n + p : n, B.appendChild(s);
            } else {
              var o = document.createElement("div");
              o.innerHTML = M.template.trim(), B.className = o.firstChild.className, B.innerHTML = o.firstChild.innerHTML;
              var l = a(".popover-header", B),
                  c = a(".popover-body", B);
              i && l && (l.innerHTML = i.trim()), n && c && (c.innerHTML = n.trim());
            }

            M.container.appendChild(B), B.style.display = "block", !B.classList.contains("popover") && B.classList.add("popover"), !B.classList.contains(M.animation) && B.classList.add(M.animation), !B.classList.contains(N) && B.classList.add(N);
          }(), y(t, B, M.placement, M.container), !B.classList.contains("show") && B.classList.add("show"), M.animation ? o(B, z) : z();
        }
      }, 20);
    }, P.hide = function () {
      clearTimeout(S), S = setTimeout(function () {
        if (B && null !== B && B.classList.contains("show")) {
          if (c.call(t, w), w.defaultPrevented) return;
          B.classList.remove("show"), M.animation ? o(B, U) : U();
        }
      }, M.delay);
    }, P.dispose = function () {
      P.hide(), W(), delete t.Popover;
    }, (t = a(t)).Popover && t.Popover.dispose(), s = t.getAttribute("data-trigger"), r = t.getAttribute("data-animation"), d = t.getAttribute("data-placement"), f = t.getAttribute("data-dismissible"), h = t.getAttribute("data-delay"), g = t.getAttribute("data-container"), p = "<button type=\"button\" class=\"close\">\xD7</button>", v = l("show", "popover"), b = l("shown", "popover"), w = l("hide", "popover"), A = l("hidden", "popover"), T = a(e.container), E = a(g), k = t.closest(".modal"), C = t.closest(".fixed-top"), x = t.closest(".fixed-bottom"), M.template = e.template ? e.template : null, M.trigger = e.trigger ? e.trigger : s || "hover", M.animation = e.animation && "fade" !== e.animation ? e.animation : r || "fade", M.placement = e.placement ? e.placement : d || "top", M.delay = parseInt(e.delay || h) || 200, M.dismissible = !(!e.dismissible && "true" !== f), M.container = T || E || C || x || k || document.body, N = "bs-popover-" + M.placement;
    var q = D();
    i = q[0], ((n = q[1]) || M.template) && (t.Popover || W(1), t.Popover = P);
  }

  function A(t, e) {
    e = e || {};
    var i,
        n,
        s,
        o,
        r,
        d = this || _global,
        u = {};

    function f() {
      var e;
      e = o.getElementsByTagName("A"), i.length !== e.length && (i.items = [], i.targets = [], Array.from(e).map(function (t) {
        var e = t.getAttribute("href"),
            n = e && "#" === e.charAt(0) && "#" !== e.slice(-1) && a(e);
        n && (i.items.push(t), i.targets.push(n));
      }), i.length = e.length), i.scrollOffset = i.isWindow ? b().y : t.scrollTop, i.items.map(function (e, n) {
        return function (e) {
          var n = i.items[e],
              s = i.targets[e],
              o = n.classList.contains("dropdown-item") && n.closest(".dropdown-menu"),
              a = o && o.previousElementSibling,
              r = n.nextElementSibling,
              d = r && r.getElementsByClassName("active").length,
              m = i.isWindow && s.getBoundingClientRect(),
              f = n.classList.contains("active") || !1,
              h = (i.isWindow ? m.top + i.scrollOffset : s.offsetTop) - u.offset,
              g = i.isWindow ? m.bottom + i.scrollOffset - u.offset : i.targets[e + 1] ? i.targets[e + 1].offsetTop - u.offset : t.scrollHeight,
              p = d || i.scrollOffset >= h && g > i.scrollOffset;
          if (!f && p) n.classList.add("active"), a && !a.classList.contains("active") && a.classList.add("active"), c.call(t, l("activate", "scrollspy", i.items[e]));else if (f && !p) n.classList.remove("active"), a && a.classList.contains("active") && !n.parentNode.getElementsByClassName("active").length && a.classList.remove("active");else if (f && p || !p && !f) return;
        }(n);
      });
    }

    function h(t) {
      r[t = t ? "addEventListener" : "removeEventListener"]("scroll", d.refresh, m), window[t]("resize", d.refresh, m);
    }

    d.refresh = function () {
      f();
    }, d.dispose = function () {
      h(), delete t.ScrollSpy;
    }, (t = a(t)).ScrollSpy && t.ScrollSpy.dispose(), n = t.getAttribute("data-target"), s = t.getAttribute("data-offset"), o = a(e.target || n), r = t.offsetHeight < t.scrollHeight ? t : window, o && (u.target = o, u.offset = parseInt(e.offset || s) || 10, (i = {}).length = 0, i.items = [], i.targets = [], i.isWindow = r === window, t.ScrollSpy || h(1), d.refresh(), t.ScrollSpy = d);
  }

  function T(t, i) {
    i = i || {};
    var n,
        s,
        r,
        d,
        u,
        m,
        f,
        h,
        g,
        p,
        v,
        L,
        b,
        y,
        w,
        A = this || _global,
        T = !1;

    function E() {
      T.style.height = "", T.classList.remove("collapsing"), s.isAnimating = !1;
    }

    function k() {
      T ? b ? E() : setTimeout(function () {
        T.style.height = y + "px", T.offsetWidth, o(T, E);
      }, 50) : s.isAnimating = !1, u = l("shown", "tab", g), c.call(h, u);
    }

    function C() {
      T && (p.style.float = "left", v.style.float = "left", L = p.scrollHeight), d = l("show", "tab", g), f = l("hidden", "tab", h), c.call(h, d), d.defaultPrevented || (v.classList.add("active"), p.classList.remove("active"), T && (y = v.scrollHeight, b = y === L, T.classList.add("collapsing"), T.style.height = L + "px", T.offsetHeight, p.style.float = "", v.style.float = ""), v.classList.contains("fade") ? setTimeout(function () {
        v.classList.add("show"), o(v, k);
      }, 20) : k(), c.call(g, f));
    }

    function x() {
      var t,
          e = s.getElementsByClassName("active");
      return 1 !== e.length || e[0].parentNode.classList.contains("dropdown") ? e.length > 1 && (t = e[e.length - 1]) : t = e[0], t;
    }

    function N() {
      return a(x().getAttribute("href"));
    }

    function P(t) {
      t.preventDefault(), h = t.currentTarget, !s.isAnimating && A.show();
    }

    A.show = function () {
      if (!(h = h || t).classList.contains("active")) {
        if (v = a(h.getAttribute("href")), g = x(), p = N(), m = l("hide", "tab", h), c.call(g, m), m.defaultPrevented) return;
        s.isAnimating = !0, g.classList.remove("active"), g.setAttribute("aria-selected", "false"), h.classList.add("active"), h.setAttribute("aria-selected", "true"), r && (t.parentNode.classList.contains("dropdown-menu") ? r.classList.contains("active") || r.classList.add("active") : r.classList.contains("active") && r.classList.remove("active")), p.classList.contains("fade") ? (p.classList.remove("show"), o(p, C)) : C();
      }
    }, A.dispose = function () {
      t.removeEventListener("click", P, !1), delete t.Tab;
    }, (t = a(t)).Tab && t.Tab.dispose(), n = t.getAttribute("data-height"), s = t.closest(".nav"), r = s && a(".dropdown-toggle", s), w = !(!e || !1 === i.height || "false" === n), s.isAnimating = !1, t.Tab || t.addEventListener("click", P, !1), w && (T = N().parentNode), t.Tab = A;
  }

  function E(t, e) {
    e = e || {};
    var i,
        n,
        s,
        r,
        d,
        u,
        m,
        f,
        h = this || _global,
        g = 0,
        p = {};

    function v() {
      i.classList.remove("showing"), i.classList.add("show"), c.call(i, m), p.autohide && h.hide();
    }

    function L() {
      i.classList.add("hide"), c.call(i, f);
    }

    function b() {
      i.classList.remove("show"), p.animation ? o(i, L) : L();
    }

    function y() {
      clearTimeout(g), t.removeEventListener("click", h.hide, !1), delete t.Toast;
    }

    h.show = function () {
      if (i && !i.classList.contains("show")) {
        if (c.call(i, d), d.defaultPrevented) return;
        p.animation && i.classList.add("fade"), i.classList.remove("hide"), i.offsetWidth, i.classList.add("showing"), p.animation ? o(i, v) : v();
      }
    }, h.hide = function (t) {
      if (i && i.classList.contains("show")) {
        if (c.call(i, u), u.defaultPrevented) return;
        t ? b() : g = setTimeout(b, p.delay);
      }
    }, h.dispose = function () {
      p.animation ? o(i, y) : y();
    }, (t = a(t)).Toast && t.Toast.dispose(), i = t.closest(".toast"), n = t.getAttribute("data-animation"), s = t.getAttribute("data-autohide"), r = t.getAttribute("data-delay"), d = l("show", "toast"), u = l("hide", "toast"), m = l("shown", "toast"), f = l("hidden", "toast"), p.animation = !1 === e.animation || "false" === n ? 0 : 1, p.autohide = !1 === e.autohide || "false" === s ? 0 : 1, p.delay = parseInt(e.delay || r) || 500, t.Toast || t.addEventListener("click", h.hide, !1), t.Toast = h;
  }

  function k(t, e) {
    e = e || {};
    var i,
        n,
        s,
        r,
        d,
        f,
        h,
        g,
        p,
        v,
        b,
        w,
        A,
        T,
        E,
        k = this || _global,
        C = null,
        x = 0,
        N = {};

    function P() {
      return t.getAttribute("title") || t.getAttribute("data-title") || t.getAttribute("data-original-title");
    }

    function B(e) {
      C && C.contains(e.target) || e.target === t || t.contains(e.target) || k.hide();
    }

    function S(t) {
      t = t ? "addEventListener" : "removeEventListener", document[t]("touchstart", B, m), window[t]("resize", k.hide, m);
    }

    function H() {
      S(1), c.call(t, h);
    }

    function M() {
      S(), N.container.removeChild(C), C = null, x = null, c.call(t, p);
    }

    function I(e) {
      t[e = e ? "addEventListener" : "removeEventListener"](L, k.show, !1), t[e](u[0], k.show, !1), t[e](u[1], k.hide, !1);
    }

    k.show = function () {
      clearTimeout(x), x = setTimeout(function () {
        if (null === C) {
          if (c.call(t, f), f.defaultPrevented) return;
          !1 !== function () {
            if (i = P()) {
              if (C = document.createElement("div"), N.template) {
                var t = document.createElement("div");
                t.innerHTML = N.template.trim(), C.className = t.firstChild.className, C.innerHTML = t.firstChild.innerHTML, a(".tooltip-inner", C).innerHTML = i.trim();
              } else {
                var e = document.createElement("div");
                e.classList.add("arrow"), C.appendChild(e);
                var n = document.createElement("div");
                n.classList.add("tooltip-inner"), C.appendChild(n), n.innerHTML = i;
              }

              C.style.left = "0", C.style.top = "0", C.setAttribute("role", "tooltip"), !C.classList.contains("tooltip") && C.classList.add("tooltip"), !C.classList.contains(N.animation) && C.classList.add(N.animation), !C.classList.contains(E) && C.classList.add(E), N.container.appendChild(C);
            }
          }() && (y(t, C, N.placement, N.container), !C.classList.contains("show") && C.classList.add("show"), N.animation ? o(C, H) : H());
        }
      }, 20);
    }, k.hide = function () {
      clearTimeout(x), x = setTimeout(function () {
        if (C && C.classList.contains("show")) {
          if (c.call(t, g), g.defaultPrevented) return;
          C.classList.remove("show"), N.animation ? o(C, M) : M();
        }
      }, N.delay);
    }, k.toggle = function () {
      C ? k.hide() : k.show();
    }, k.dispose = function () {
      I(), k.hide(), t.setAttribute("title", t.getAttribute("data-original-title")), t.removeAttribute("data-original-title"), delete t.Tooltip;
    }, (t = a(t)).Tooltip && t.Tooltip.dispose(), n = t.getAttribute("data-animation"), s = t.getAttribute("data-placement"), r = t.getAttribute("data-delay"), d = t.getAttribute("data-container"), f = l("show", "tooltip"), h = l("shown", "tooltip"), g = l("hide", "tooltip"), p = l("hidden", "tooltip"), v = a(e.container), b = a(d), w = t.closest(".modal"), A = t.closest(".fixed-top"), T = t.closest(".fixed-bottom"), N.animation = e.animation && "fade" !== e.animation ? e.animation : n || "fade", N.placement = e.placement ? e.placement : s || "top", N.template = e.template ? e.template : null, N.delay = parseInt(e.delay || r) || 200, N.container = v || b || A || T || w || document.body, E = "bs-tooltip-" + N.placement, (i = P()) && (t.Tooltip || (t.setAttribute("data-original-title", i), t.removeAttribute("title"), I(1)), t.Tooltip = k);
  }

  var C = {};

  function x(t, e) {
    Array.from(e).map(function (e) {
      return new t(e);
    });
  }

  function N(t) {
    for (var e in t = t || document, C) x(C[e][0], t.querySelectorAll(C[e][1]));
  }

  function P(t, e) {
    Array.from(e).map(function (e) {
      return e[t].dispose();
    });
  }

  C.Alert = [r, "[data-dismiss=\"alert\"]"], C.Button = [d, "[data-toggle=\"buttons\"]"], C.Carousel = [f, "[data-ride=\"carousel\"]"], C.Collapse = [h, "[data-toggle=\"collapse\"]"], C.Dropdown = [p, "[data-toggle=\"dropdown\"]"], C.Modal = [v, "[data-toggle=\"modal\"]"], C.Popover = [w, "[data-toggle=\"popover\"],[data-tip=\"popover\"]"], C.ScrollSpy = [A, "[data-spy=\"scroll\"]"], C.Tab = [T, "[data-toggle=\"tab\"]"], C.Toast = [E, "[data-dismiss=\"toast\"]"], C.Tooltip = [k, "[data-toggle=\"tooltip\"],[data-tip=\"tooltip\"]"], document.body ? N() : document.addEventListener("DOMContentLoaded", function t() {
    N(), document.removeEventListener("DOMContentLoaded", t, !1);
  }, !1);
  return {
    Alert: r,
    Button: d,
    Carousel: f,
    Collapse: h,
    Dropdown: p,
    Modal: v,
    Popover: w,
    ScrollSpy: A,
    Tab: T,
    Toast: E,
    Tooltip: k,
    initCallback: N,
    removeDataAPI: function (t) {
      for (var e in t = t || document, C) P(e, t.querySelectorAll(C[e][1]));
    },
    componentsInit: C,
    Version: "3.0.13"
  };
});
export default exports;